import "./style.css";
import Typed from "react-typed";
import MintBox from "./mint_box";
import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
import { InlineVideoPlayer } from "../whistia";
import img10 from "../../assets/images/10.png";
import img12 from "../../assets/images/12.png";
import img13 from "../../assets/images/13.png";
import { MdDoubleArrow } from "react-icons/md";

const fade_ = keyframes`
from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

function Cover() {
  return (
    <div className="cover_container_main">
      <div className="first_section">
        <div className="first_section_layer">
          <div className="first_section_content">
            <div className="first_video_cont">
              <Reveal triggerOnce={true} keyframes={fade_}>
                {/* <MintBox /> */}

                <div className="main_headline_cont">
                  <h1 style={{fontSize: "50px"}}>FADED ARCADE VIP PASS SOLD OUT</h1>

                  <a
                    href="https://opensea.io/collection/fadedarcade"
                    target="_blank"
                    rel="noreferrer"
                    className="text-uppercase connect_btn"
                  >
                    <span>
                      <MdDoubleArrow />
                    </span>
                    Buy On Opensea
                  </a>
                </div>
              </Reveal>
            </div>

            <>
              {/* <div className="first_video_cont">
                <InlineVideoPlayer id={"ecmw66klki"} />
              </div> */}
              <div className="main_cover_p">
                <p>
                  Pixel Art and Retro Games have never been so popular. We’ve
                  combined both worlds to create a unique Game Studio, Faded
                  Arcade. Minting a Faded Arcade NFT will give you a stake in
                  our studio and let you profit from it. Click the button below
                  to get whitelisted and secure your spot!
                </p>
              </div>

              <div className="cover_icons">
                <span
                  onClick={() =>
                    window.open("https://twitter.com/fadedarcadenft", "_blank")
                  }
                >
                  <img src={img13} alt="" />
                </span>
                <span
                  onClick={() =>
                    window.open("https://fadedarcade.store/", "_blank")
                  }
                >
                  <img src={img10} alt="" />
                </span>
                <span
                  onClick={() =>
                    window.open(
                      "https://www.youtube.com/channel/UChZc26be1e_ybn1GPqYF2Hw",
                      "_blank"
                    )
                  }
                >
                  <img src={img12} alt="" />
                </span>
              </div>
            </>
          </div>
        </div>
      </div>

      <div className="moving_text_parent">
        <div className="moving_text">
          <Typed
            strings={["VIP Pass Sold Out - Game Pass Coming Soon!"]}
            typeSpeed={85}
            backSpeed={50}
            loop
            showCursor
            cursorChar="|"
          />
        </div>
      </div>
    </div>
  );
}

export default Cover;
