import "./index.css"
import { keyframes } from "@emotion/react";
import { MdDoubleArrow } from "react-icons/md";

const fade_left = keyframes`
from {
    opacity: 0;
    -webkit-transform: translate3d(100px, 0, 0);
    transform: translate3d(1p0px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
`;
const fade_right = keyframes`
from {
    opacity: 0;
    -webkit-transform: translate3d(=100px, 0, 0);
    transform: translate3d(-100px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
`;



function Roadmaptest() {

    return (
        <div className="main_benefit_container">
            <h1 className="main_h">
                Join the first ever NFT-powered gaming pixelverse and enjoy the following exclusive benefits
            </h1>
            <p className="mainP">
                We’ve designed Faded Arcade from the ground up with our minters and holders in mind. By minting a NFT you will be able to earn from multiple income streams and see your NFT value increase with time while enjoying our unique NFT experience.
            </p>
            <div className="benefits_container">
                <div className="benefits_column_left">
                    <h2>Faded Arcade VIP Pass:</h2>
                    <p>1) Instant Access To Faded Arcade Season #1</p>
                    <p>2) 10 x FREE Mint of the Faded Arcade Season #2 - On Release [Anticipated 2023]</p>
                    <p>3) 10 x FREE Mint of the Faded Arcade Season #3 - On Release [Anticipated 2024]</p>
                    <p>4) PLUS+  Additional Exclusive FREE Mint Opportunities For The Lifetime Of The Project [Hamster Jamz Included]</p>
                    <p>5) Voting Rights On Standalone And Future Series Game Releases</p>
                    <p>6) Early Access and Exclusive Merch Drops</p>
                    <p>7) 4 Different NFT Passes With Different Rarities With Future Benefits To Be Revealed At The Start Of Season #2 - Pixel Purple 5%, Gamer Gold 10%, Shoot’Em-Up Silver 30% and Battling Bronze 55%</p>
                    <div className="main_headline_cont">
                        <h1 style={{ fontSize: "50px" }}>SOLD OUT</h1>

                        <a
                            href="https://opensea.io/collection/fadedarcade"
                            target="_blank"
                            rel="noreferrer"
                            className="text-uppercase connect_btn"
                        >
                            <span>
                                <MdDoubleArrow />
                            </span>
                            Buy On Opensea
                        </a>
                    </div>
                </div>
                <div className="benefits_column_right">
                    <h2>Faded Arcade Game Pass for 'Season #1'</h2>

                    <p>1) Lifetime Access To The Sunnyvale Pixelverse </p>
                    <ul>
                        <li>- Ted's Sounds</li>
                        <li>- Mega Movies</li>
                        <li>- Exclusive Merch</li>
                    </ul>
                    <p>2) Lifetime Access To 10 x Restomod Season #1 Arcade Games</p>
                    <ul>
                        <li>- Battle Castle</li>
                        <li>- Block Blaster</li>
                        <li>- Galaxy Raiders</li>
                        <li>- Goblin Wood</li>
                        <li>- Haunted Pussy</li>
                        <li>- Medusa's Lair</li>
                        <li>- Mega City</li>
                        <li>- Spiders From Mars</li>
                        <li>- Star Command</li>
                        <li>- Time Lab Escape</li>
                    </ul>
                    <p>3) Lifetime Access To Hidden Pixelverse Games</p>
                    <ul>
                        <li>- Space Invaders</li>
                        <li>- Pong</li>
                        <li>- Ball Buster</li>
                    </ul>
                    <p>4) Access To New & Upcoming Pixelverse Features</p>
                    <ul>
                        <li>- Candy's Roller Disco</li>
                        <li>- Blonnell's Ghost Train Ride</li>
                    </ul>
                    <p>5) Guaranteed Whitelist For Season #2 On Release In 2023</p>
                </div>
            </div>
        </div>

    )
}

export default Roadmaptest;
