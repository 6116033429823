import "./index.css";
import img from "../../assets/images/n_logo.png";
import { HashLink } from "react-router-hash-link";
import { MdDoubleArrow } from "react-icons/md";

function Navbar() {
  return (
    <div className="navbar_container_bg">
      <div className="navbar_container">
        <div className="navbar_logo">
          <img src={img} alt="" />
        </div>
        <div className="navbar_text">
          <span>
            <HashLink smooth to={"#about"}>
              ABOUT & WHITEPAPER
            </HashLink>{" "}
            *&nbsp;
          </span>
          <span>
            <a
              href="https://leaderboard.fadedarcade.com/"
              target="_blank"
              rel="noreferrer"
            >
              LEADERBOARD
            </a>{" "}
            *&nbsp;
          </span>
          <span>
            <HashLink smooth to={"#team"}>
              CASTLIST
            </HashLink>{" "}
            *&nbsp;
          </span>
          <span>
            <HashLink smooth to={"#faq"}>
              FAQ
            </HashLink>
          </span>
        </div>
        <div className="navbar_button">
          <button
            onClick={() =>
              window.open("https://sunnyvale.fadedarcade.com", "_blank")
            }
          >
            <span>
              <MdDoubleArrow />
            </span>
            ENTER ARCADE
          </button>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
