import "./index.css";
import img from "../../assets/images/n_logo.png";

const Credits = () => {
  return (
    <>
      <div className="navbar_container_bg">
        <div className="navbar_container">
          <div className="navbar_logo">
            <img src={img} alt="" />
          </div>
          <div className="navbar_text"></div>
          <div className="navbar_text">
            <span>
              <a
                className="twitter-share-button"
                href="https://twitter.com/intent/tweet?text=Check%20out%20the%20%23FadedArcade%20and%20the%20cool%20people%20behind%20it%20-%20Faded%20Arcade%20Credits%20Page&url=https%3A%2F%2Ffadedarcade.com%2Fcredits"
              >
                Share On Twitter
              </a>
            </span>
          </div>
        </div>
      </div>

      <div className="package_container">
        <h2 className="main_h fs-43">
          Faded Arcade [Part One - The Prequel] Project Credits:
        </h2>
        <p className="mainP" onClick={() =>window.open("https://twitter.com/keeffy99", "_blank")}>Brand Ambassador – Paul O’Keeffe</p>
        <p className="mainP">Creative Director – Simon Hodgkinson</p>
        <p className="mainP">Game Director – Simon Philips</p>
        <p className="mainP">Executive Producer – Jeremy Gislason</p>
        <p className="mainP">Technical Director – JP Schoeffel</p>
      </div>

      <div className="package_container">
        <h2 className="main_h fs-43">
          Artwork and Videos with Credit and Special Thanks to:
        </h2>
        <p className="mainP">
          Mucho Pixels - @muchopixels & https://www.instagram.com/muchopixels/
        </p>
        <p className="mainP">
          Mikhail Nilov – Dreamwood Studio -
          https://www.instagram.com/dreamwood.studio/
        </p>
        <p className="mainP">
          Daniel Dash - https://www.instagram.com/daniel__dash/
        </p>
        <p className="mainP">
          Savagerus Art - https://www.instagram.com/savagerus.art/
        </p>
        <p className="mainP">Junkyard AI - @Junkyard_AI</p>
        <p className="mainP">SomePX – Eeve Sompx - @somepx</p>
      </div>

      <div className="package_container">
        <h2 className="main_h fs-43">
          Music and Soundtracks with Credit and Special Thanks to:
        </h2>
        <p className="mainP">
          BATTLE CASTLE - Floppy Disk Is Still A Thing - Luca Francini
          [lucafrancini.com]
        </p>
        <p className="mainP">
          BLOCK BLASTER - Final Fight - Hohl Fury [soundcloud.com/hohl_fury]
        </p>
        <p className="mainP">
          GALAXY RAIDERS - Retro Electro Surfer - Isidor Bobinec
          [soundcloud.com/cyborglaw]
        </p>
        <p className="mainP">
          GOBLIN WOOD – Video Games In 8Bit - Airport Music
        </p>
        <p className="mainP">
          HAUNTED PUSSY - Crazy Halloween EDM Party - Nicest Possible Way
          [Andrew Flesher]
        </p>
        <p className="mainP">
          MEDUSA'S LAIR – Sexy Cool - Pink Zebra [pinkzebramusic.com]
        </p>
        <p className="mainP">
          MEGA CITY - Death Race - Cell3Xo [soundcloud.com/patrick-schlebes]
        </p>
        <p className="mainP">
          SPIDERS FROM MARS – Rapata-Chapata – Dirty Flint
          [https://soundcloud.com/aleksander-bielash]
        </p>
        <p className="mainP">
          STAR COMMAND - 8 Bit Video - Sound Forces [soundcloud.com/jc86-1-1]
        </p>
        <p className="mainP">
          TIME LAB ESCAPE - The Treasure – Snabisch
          [https://soundcloud.com/Snabisch]
        </p>
        <p className="mainP">
          MEGALOPOLIS – Tik Tok Clock - Red Octopus [redoctopusweb.com]
        </p>
        <p className="mainP">
          SUNNYVALE DAY MODE - Italo Disco 80's – Dirty Flint
          [https://soundcloud.com/aleksander-bielash]
        </p>
        <p className="mainP">
          SUNNYVALE NIGHT MODE – Night Driver – Grach
          [https://soundcloud.com/vladimir_ageev]
        </p>
        <p className="mainP">
          WHITE PAPER – Retro Flashback - Dmitro Khatskevych -
          [https://soundcloud.com/dimadjdocent]
        </p>
        <p className="mainP">
          PERFECT SUMMERTIME – Vintetro Music –
          [https://soundcloud.com/vintetro]
        </p>
      </div>

      <div className="package_container">
        <h2 className="main_h fs-43">
          And Special Thanks For The Inspiration To Develop The Faded Arcade And
          Many, Many Years Of Amazing Gaming To Just Some Of These Heroes...
        </h2>
        <p className="mainP">
          Nolan Bushnell & Ted Dabney, Lyle Rains & Ed Logg, Tomohiro Nishikado,
          Kazunori Sawano, Eugene Jarvis, Toru Iwatani, Hiroshi Yamauchi &
          Shigeru Miyamoto, Akira Fujita & Hiroshi Tsujino, Brian Colin & Jeff
          Nauman, Takashi Nishiyama, Rick Dyer & Don Bluth, Tom Petit &
          Toshihiro Nagoshi, Dona Bailey, Dave Theurer, Shigeru Yokoyama, Warren
          Davis & Jeff Lee, Tokuro Fujiwara, Yu Suzuki, Ed Boon & John Tobias,
          Hiroyuki Imabayashi and the many unsung developers including The Nuggs
          Brothers, Breakpoint Games, Strategic Defence Collective, Prog Games,
          Starcourt Games, Sunny Valley Games, Bifco Games and Gamesware Games
          along with the publishers, designers, programmers, artists and
          composers that shaped the golden age of arcade gaming.
        </p>
      </div>

      <div className="package_container pb-5">
        <h2 className="main_h fs-43">
          <strong>THANK YOU ALL!</strong>
        </h2>
      </div>

      <div className="navbar_container_bg">
        <div className="navbar_container">
          <div className="navbar_logo">
            <img src={img} alt="" />
          </div>
          <div className="navbar_text">
            <span>
              <a
                rel="noreferrer"
                href="https://fadedarcade.com/"
                target="_blank"
              >
                FadedArcade.com
              </a>{" "}
              *&nbsp;
            </span>
            <span>
              <a
                rel="noreferrer"
                href="https://twitter.com/thefadedarcade"
                target="_blank"
              >
                Twitter
              </a>{" "}
              *&nbsp;
            </span>
            <span>
              <a
                rel="noreferrer"
                href="https://www.youtube.com/c/fadedarcade"
                target="_blank"
              >
                YouTube
              </a>
            </span>
          </div>
          <div className="navbar_text">
            <span>
              <a
                className="twitter-share-button"
                href="https://twitter.com/intent/tweet?text=Check%20out%20the%20%23FadedArcade%20and%20the%20cool%20people%20behind%20it%20-%20Faded%20Arcade%20Credits%20Page&url=https%3A%2F%2Ffadedarcade.com%2Fcredits"
              >
                Share On Twitter
              </a>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Credits;
