import "./index.css"
import img1 from "../../assets/images/g9.gif"
import img2 from "../../assets/images/g10.gif"
import img3 from "../../assets/images/g11.gif"
import img4 from "../../assets/images/g13.gif"


function WhyJoin() {
    return (
        <div className="whyjoin_container">
            <div className="whyjoin_container_layer">

                <div className="whyjoin_heading">
                    <h1>4 Different NFT Passes With Different Rarities</h1>
                </div>
                <div className="whyjoin_images">
                    <div className="why_wrapper">
                        <div className="sub_join_image">
                            <img src={img1} alt="" />
                        </div>
                        <h3> Pixel Purple 5%</h3>
                    </div>

                    <div className="why_wrapper">
                        <div className="sub_join_image">
                            <img src={img2} alt="" />
                        </div>
                        <h3>Gamer Gold 10%</h3>

                    </div>
                    <div className="why_wrapper">
                        <div className="sub_join_image">
                            <img src={img4} alt="" />
                        </div>
                        <h3>Shoot’Em-Up Silver 30%</h3>

                    </div>
                    <div className="why_wrapper">
                        <div className="sub_join_image">
                            <img src={img3} alt="" />
                        </div>
                        <h3>Battling Bronze 55%</h3>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default WhyJoin;