import "./index.css";
// import AsNavFor from "./slider";

import s1 from "../../assets/images/s1.png"
import s2 from "../../assets/images/s2.png"
import s3 from "../../assets/images/s3.png"
import s4 from "../../assets/images/s4.png"
import s5 from "../../assets/images/s5.png"
import s6 from "../../assets/images/s6.png"
import s7 from "../../assets/images/s7.png"
import s8 from "../../assets/images/s8.png"
import s9 from "../../assets/images/s9.png"
import s10 from "../../assets/images/s10.png"



import { Swiper, SwiperSlide } from "swiper/react";
import './index.css'
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation, Autoplay } from "swiper";
import "./index.css";
import {  VideoPlayerModal } from "../whistia";


function SwiperModal() {

    return (
        <>
            <div className='maestros_container_bg'>
                <div className='maestros_container'>

                    <div className='maestros_content'>
                        <h1 >FADEDARCADE SERIES#1 GAMES FOOTAGE</h1>
                        {/* <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Impedit, odio ab error explicabo ex voluptas quidem fugiat eum magni ducimus culpa sint autem ea officiis accusantium illo recusandae voluptatibus. Aperiam!</p> */}
                    </div>

                    <div className='maestros_slider'>
                        <Swiper
                            className="mySwiper"
                            pagination={{
                                clickable: true,
                            }}
                            autoplay={{
                                delay: 3000,
                                disableOnInteraction: false,
                            }}
                            modules={[Autoplay, Pagination, Navigation]}
                            navigation={true}
                            breakpoints={{
                                640: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                },
                                768: {
                                    slidesPerView: 3,
                                    spaceBetween: 40,
                                },
                                1100: {
                                    slidesPerView: 4,
                                    spaceBetween: 50,
                                },
                            }}
                        >
                            <SwiperSlide>
                                <div className="img_container">
                                    <VideoPlayerModal url={s6} id='j1yjsfo2md' />
                                    <h1>Battle Castle</h1>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className="img_container">
                                    <VideoPlayerModal url={s3} id='ksf7hopsxh' />
                                    <h1>Block Blaster</h1>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="img_container">
                                    <VideoPlayerModal url={s10} id='h1cdauvuas' />
                                    <h1>Galaxy Raiders</h1>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="img_container">
                                    <VideoPlayerModal url={s7} id='70s15sq3i1' />
                                    <h1>Goblin Wood</h1>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="img_container">
                                    <VideoPlayerModal url={s5} id='2fd349x70e' />
                                    <h1>Haunted Pussy</h1>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="img_container">
                                    <VideoPlayerModal url={s8} id='fsm0ophxgf' />
                                    <h1>Medusa's Lair</h1>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="img_container">
                                    <VideoPlayerModal url={s4} id='ei064r0s47' />
                                    <h1>Mega City</h1>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="img_container">
                                    <VideoPlayerModal url={s2} id='62hasjsyb8' />
                                    <h1>Spiders From Mars</h1>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="img_container">
                                    <VideoPlayerModal url={s1} id='6imy0po67k' />
                                    <h1>Star Command</h1>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="img_container">
                                    <VideoPlayerModal url={s9} id='pa0edxqwub' />
                                    <h1>Time Lab Escape</h1>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                    <div className="bottom_slider_content">
                        <p><span style={{color:'#6cff00'}}> IMPORTANT:</span>  In addition to the ten games above you'll also find several hidden bonus games dotted in and around Sunnyvale itself including the mysterious 'Megalopolis' machine...</p>
                    </div>
                </div>
            </div>

        </>
    );
}

export default SwiperModal