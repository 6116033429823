import axios from "axios";
import { config } from ".";

export default class Request {
  static registry(data) {
    const { ajax_request_url, pid } = config;
    return axios.post(ajax_request_url + "registry", { ...data, pid });
  }

  static get_signature({ address }) {
    const { ajax_request_url, pid } = config;
    return axios.get(ajax_request_url + `get_signature/${pid}/${address}`);
  }

  static get_proof({ address }) {
    const { ajax_request_url, pid } = config;
    return axios.get(ajax_request_url + `whitelist/proof`, {
      params: { address, pid },
    });
  }

  static in_whitelist({ address }) {
    const { ajax_request_url, pid } = config;
    return axios.get(ajax_request_url + `whitelist/check`, {
      params: { address, pid },
    });
  }

  static check_nft({ address }) {
    const { ajax_request_url } = config;

    return axios.get(ajax_request_url + `nft/check`, {
      params: { address },
    });
  }

  static rewards({ address }) {
    const { restapi } = config.splitter;

    return axios.get(`${restapi}nft/merkletree/proof/${address}`);
  }

  static minter({ address, count }) {
    const { restapi } = config.splitter;
    return axios.post(`${restapi}nft/minter/`, { address, count });
  }
}
