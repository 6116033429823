import React from "react";
import { AppConfig, Request, config } from "../../../../config";
import { BigNumber, utils } from "ethers";
import Alert from "../alert";
import { HiMinus, HiPlus } from "react-icons/hi";
import { MdDoubleArrow } from "react-icons/md";

class Mint extends React.Component {
  constructor() {
    super();

    this.state = {
      mintAmount: 1,
      alert: null,
      minting: false,
      price: 0,
      totalAmount: 0,
    };
  }

  componentDidMount() {
    const { cost, whitelistCost, isWhitelisted } = this.context.ethers;
    const price = parseFloat(
      utils.formatUnits(isWhitelisted ? whitelistCost : cost, "ether")
    );

    if (isWhitelisted) {
      this.setState({
        alert: {
          type: "success",
          message: config.messages.won_raffle,
        },
      });
    }

    this.setState({ price, totalAmount: price });
  }

  decrementMintAmount() {
    let { mintAmount, price, totalAmount } = this.state;
    let newMintAmount = mintAmount - 1;

    totalAmount -= price;

    if (newMintAmount < 1) {
      newMintAmount = 1;
      totalAmount = price;
    }

    this.setState({
      mintAmount: newMintAmount,
      totalAmount,
    });
  }

  incrementMintAmount() {
    let { mintAmount, price, totalAmount } = this.state;
    const { maxMintAmount } = this.context.ethers;
    let newMintAmount = mintAmount + 1;

    const max = parseInt(utils.formatUnits(maxMintAmount, "wei"));

    totalAmount += price;

    if (newMintAmount > max) {
      newMintAmount = max;
      totalAmount = price * newMintAmount;
    }

    this.setState({
      mintAmount: newMintAmount,
      totalAmount,
    });
  }

  async mint() {
    const { ethers } = this.context;
    const { mintAmount } = this.state;
    const defaultGasLimit = config.gas_limit;

    const koef = [1];

    if (mintAmount > 1) {
      for (let i = 0; i < mintAmount - 1; i++) {
        koef.push(1.3);
      }
    }

    const totalKoef = koef
      .reduce((accumulator, currentValue) => accumulator * currentValue)
      .toFixed(2);
    const gasLimit = BigNumber.from(Math.round(defaultGasLimit * totalKoef));

    this.setState({ minting: true });

    try {
      if (ethers.isWhitelisted) {
        const proof = await Request.get_proof({
          address: ethers.address,
        });

        if (proof.data.status === "failed") {
          this.setState({
            minting: false,
            alert: {
              type: "error",
              message:
                "Your address is not whitelisted. Minting is not allowed as of now.",
            },
          });

          return false;
        }

        const transaction = await ethers.contract.presaleMint(
          BigNumber.from(mintAmount),
          proof.data.result,
          {
            gasLimit: gasLimit,
            value: ethers.whitelistCost.mul(mintAmount),
          }
        );

        this.setState({
          minting: true,
          alert: {
            type: "success",
            message: config.messages.minting_message,
          },
        });

        transaction
          .wait()
          .then((response) => {
            console.log("Success", response);

            if (response.status === 1) {
              this.setState({
                minting: false,
                alert: {
                  type: "success",
                  message: config.messages.success_transaction,
                },
              });

              Request.minter({ address: ethers.address, count: mintAmount });
            }
          })
          .catch((err) => {
            console.log("Err:", err);

            this.setState({
              alert: {
                type: "error",
                message: config.messages.failed_transaction,
              },
              minting: false,
            });
          });
      } else {
        const transaction = await ethers.contract.mint(
          BigNumber.from(mintAmount),
          {
            gasLimit: gasLimit,
            value: ethers.cost.mul(mintAmount),
          }
        );

        this.setState({
          minting: true,
          alert: {
            type: "success",
            message: config.messages.minting_message,
          },
        });

        transaction
          .wait()
          .then((response) => {
            console.log("Success", response);

            if (response.status === 1) {
              this.setState({
                minting: false,
                alert: {
                  type: "success",
                  message: config.messages.success_transaction,
                },
              });

              Request.minter({ address: ethers.address, count: mintAmount });
            }
          })
          .catch((err) => {
            console.log("Err:", err);

            this.setState({
              alert: {
                type: "error",
                message: config.messages.failed_transaction,
              },
              minting: false,
            });
          });
      }
    } catch (err) {
      const message = err.message ? err.message : err.error.message;

      this.setState({
        alert: { type: "error", message },
        minting: false,
      });

      console.log(err);
    }
  }

  render() {
    const { mintAmount, alert, minting, totalAmount } = this.state;
    const { maxMintAmount, isWhitelisted } = this.context.ethers;

    return (
      <div className="mint_container">
        <div className="message_box">
          <p className="alert-p">
            Minting Is Opened
            {isWhitelisted && (
              <>
                <br />
                For Whitelisted Addresses
              </>
            )}
          </p>
        </div>

        {alert && <Alert {...alert} />}

        <div className="sellector_buttons_wrapper">
          <button
            className="plus_minus_button"
            onClick={this.decrementMintAmount.bind(this)}
          >
            <HiMinus />
          </button>
          <div>
            <span className="quantity"> QUANTITY</span>
            <span className="mint_qantity">{mintAmount}</span>
          </div>

          <button
            className="plus_minus_button"
            onClick={this.incrementMintAmount.bind(this)}
          >
            <HiPlus />
          </button>
        </div>

        <p className="mint_price">{totalAmount.toFixed(4)} ETH</p>

        <p className="text-center inst_para text-white font-weight-bold">
          {config.messages.mint_amount.replace(
            /{\$}/,
            utils.formatUnits(maxMintAmount, "wei")
          )}
        </p>

        <button
          className="connect_btn"
          disabled={minting}
          onClick={this.mint.bind(this)}
        >
          <span>
            <MdDoubleArrow />
          </span>
          MINT NOW
        </button>
      </div>
    );
  }
}

Mint.contextType = AppConfig;

export default Mint;
