import React, { useRef } from "react";


function VideoPlayerModal({ url, id }) {
    const ref = useRef()

    return (
        <div ref={ref} className={`wistia_embed wistia_async_${id} popover=true viddeo popoverDisableAutoPlay=false playerColor=0498d6 popoverContent=false popoverShowOnLoad=false muted=false playbar=false popoverBorderWidth=2`} ><img src={url} alt="" /></div>
    )
}


function VideoPlayer({ url, id }) {
    const ref = useRef()

    return (
        <div ref={ref} className={`uniqueId_Whistia wistia_embed wistia_async_${id} viddeo autoplay popover=true playerColor=0498d6 muted=true playbar=false `} ><img src={url} alt="" /></div>
    )
}

function InlineVideoPlayer({ id }) {
    const ref = useRef()

    return (
        <div ref={ref} style={{maxWidth:'600px', width:'600px', height:'auto'}} className={`uniqueId_Whistia_inline wistia_embed wistia_async_${id} viddeo videoFoam=true playerColor=a200ff `} ></div>
    )
}


export { VideoPlayerModal, VideoPlayer, InlineVideoPlayer }