import "./index.css"
import img from "../../assets/images/14.png"
import GetWhitelist from "../get_whitelist";
import {VideoPlayerModal} from "../whistia";

function Not() {
    return (
        <div className="not_container">
            <div className="not_content">
                <h1>Not All Is As It Seems At The Faded Arcade:</h1>
                <p>When your fingers ache from too much platforming, SHMUPing, exploring, strategizing, defending, fighting and good old fashioned 'blowing s*&t up' fun... you can begin your quest! A journey to uncover secret Easter eggs, clues and 'lost' story progression content. Some hidden around Sunnyvale, more scattered across the web waiting to be found.</p>
                <p>You'll find red-herrings and intriguing distractions aplenty, but as more is revealed you're in for some truly mind bending plot twists and revelations.</p>
                <p><b>... But the craziest thing of all is YOUR game has <u>ALREADY</u> started.</b></p>
                <p>To win it you'll just have to be bold enough to play.</p>
                {/* <button onClick={() => window.open('https://discord.gg/fadedarcade', '_blank')}>  <span><MdDoubleArrow /></span>GET WHITELISTED</button> */}
                <GetWhitelist/>
            </div>
            <div className="not_image">
            <VideoPlayerModal id='4n8w59b4xz' url={img}/>
            </div>
        </div>
    )
}

export default Not;