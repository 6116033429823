import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { useState } from "react";
import "./index.css";
import { MdDoubleArrow } from "react-icons/md";

function WhitelistModal({ html }) {
  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  return (
    <>
      <button className="text-uppercase" onClick={onOpenModal}>
        <span>
          <MdDoubleArrow />
        </span>
        {html}
      </button>

      <Modal
        classNames={{
          overlay: "customOverlay",
          modal: "customModal",
        }}
        closeOnEsc={true}
        center={true}
        open={open}
        onClose={onCloseModal}
      >
        <iframe
          title="123"
          className="whitelistModal"
          loading="lazy"
          src="https://quiet-horse-446e9f.netlify.app/"
          style={{ display: "block", margin: "0 auto" }}
          frameBorder="0"
        ></iframe>
      </Modal>
    </>
  );
}

export default WhitelistModal;
