import ClaimReward from "../components/nft/components/claim_reward";
import img from "./../assets/images/n_logo.png";

const Rewards = () => {
  return (
    <>
      <div className="navbar_container_bg">
        <div className="navbar_container">
          <div className="navbar_logo">
            <img src={img} alt="" />
          </div>
          <div className="navbar_text"></div>
          <div className="navbar_text">
            <span>
              <a
                className="twitter-share-button"
                href="https://twitter.com/intent/tweet"
                data-size="large"
                data-text="I made it to the top of the Faded Arcade Leaderboard!"
                data-url="https://leaderboard.fadedarcade.com"
                data-hashtags="fadedarcade, faded arcade, nft"
                data-via="the fadedarcade"
              >
                Share On Twitter
              </a>
            </span>
          </div>
        </div>
      </div>

      <div className="package_container pb-5 flex-grow-1">
        <div className="main_cover_p">
          <p className="text-center">
            As an original minter of Faded Arcade NFTs, you can claim your share
            of the 10% Royalties using the button below.
            <br />
            Around the 15th of each month and during the next 12 months (from
            the data you minted your NFTs), you will be able to claim your
            rewards.
          </p>
        </div>
        <ClaimReward />
      </div>

      <div className="navbar_container_bg">
        <div className="navbar_container">
          <div className="navbar_logo">
            <img src={img} alt="" />
          </div>
          <div className="navbar_text">
            <span>
              <a
                rel="noreferrer"
                href="https://fadedarcade.com/"
                target="_blank"
              >
                FadedArcade.com
              </a>{" "}
              *&nbsp;
            </span>
            <span>
              <a
                rel="noreferrer"
                href="https://twitter.com/thefadedarcade"
                target="_blank"
              >
                Twitter
              </a>{" "}
              *&nbsp;
            </span>
            <span>
              <a
                rel="noreferrer"
                href="https://www.youtube.com/c/fadedarcade"
                target="_blank"
              >
                YouTube
              </a>
            </span>
          </div>
          <div className="navbar_text">
            <span>
              <a
                className="twitter-share-button"
                href="https://twitter.com/intent/tweet"
                data-size="large"
                data-text="I made it to the top of the Faded Arcade Leaderboard!"
                data-url="https://leaderboard.fadedarcade.com"
                data-hashtags="fadedarcade, faded arcade, nft"
                data-via="the fadedarcade"
              >
                Share On Twitter
              </a>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Rewards;
