import React from "react";

import { ethers, utils } from "ethers";
import { config, Helper, Request, _web3Modal } from "../../../../config";
import { MdDoubleArrow } from "react-icons/md";
import { toast } from "react-toastify";

class Registry extends React.Component {
  constructor() {
    super();

    this.state = {
      alert: null,
      connected: false,
      has_balance: utils.parseEther(config.has_balance),
    };
  }

  async handleConnect() {
    this.setState({ alert: null });
    const provider = await _web3Modal.connect();

    this.provider = new ethers.providers.Web3Provider(provider);
    const network_id = await this.provider.send("net_version");

    if (parseInt(network_id) !== parseInt(config.network)) {
      toast.error(`Change network to ${Helper.networkName(config.network)}.`, {
        autoClose: 10000,
      });
      return false;
    }

    try {
      const signer = this.provider.getSigner();
      const address = await signer.getAddress();

      // Check account balance
      if (config.check_balance) {
        const { has_balance } = this.state;
        const balance = await signer.getBalance();

        if (balance.lt(has_balance)) {
          toast.error(
            `Sorry, your account balance is lower than the required minimum. Please add funds and retry. You need at least ${config.has_balance} ETH to register.`,
            {
              autoClose: 10000,
            }
          );
          return false;
        }
      }

      const { data } = await Request.registry({ address });

      if (data.status === "success") {
        toast.success(config.messages.success_registry, {
          autoClose: 10000,
        });
      } else {
        toast.error(config.messages.success_registry, {
          autoClose: 10000,
        });
      }
    } catch (err) {
      toast.error(err.message, {
        autoClose: 10000,
      });
    }
  }

  render() {
    const { connected } = this.state;

    return (
      <>
        {!connected && (
          <button className="" onClick={this.handleConnect.bind(this)}>
            <span>
              <MdDoubleArrow />
            </span>
            GET WHITELISTED
          </button>
        )}
      </>
    );
  }
}

export default Registry;
