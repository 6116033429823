import "./index.css";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { useAccordionButton, AccordionContext } from "react-bootstrap";
import { useContext } from "react";

function ContextAwareToggle({ children, eventKey, callback }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <div
      className={
        isCurrentEventKey ? "faq_header faq_header_active" : "faq_header"
      }
      onClick={decoratedOnClick}
    >
      <h3>{children} </h3>
      <button className={isCurrentEventKey ? "b_active" : ""}>
        <svg
          className="tcb-icon"
          viewBox="0 0 24 24"
          data-id="icon-gesture-tap-button-solid"
          data-name=""
        >
          <path d="M13 5C15.21 5 17 6.79 17 9C17 10.5 16.2 11.77 15 12.46V11.24C15.61 10.69 16 9.89 16 9C16 7.34 14.66 6 13 6S10 7.34 10 9C10 9.89 10.39 10.69 11 11.24V12.46C9.8 11.77 9 10.5 9 9C9 6.79 10.79 5 13 5M20 20.5C19.97 21.32 19.32 21.97 18.5 22H13C12.62 22 12.26 21.85 12 21.57L8 17.37L8.74 16.6C8.93 16.39 9.2 16.28 9.5 16.28H9.7L12 18V9C12 8.45 12.45 8 13 8S14 8.45 14 9V13.47L15.21 13.6L19.15 15.79C19.68 16.03 20 16.56 20 17.14V20.5M20 2H4C2.9 2 2 2.9 2 4V12C2 13.11 2.9 14 4 14H8V12L4 12L4 4H20L20 12H18V14H20V13.96L20.04 14C21.13 14 22 13.09 22 12V4C22 2.9 21.11 2 20 2Z"></path>
        </svg>
      </button>
    </div>
  );
}

function Faq() {
  return (
    <div id="faq" className="faq_container">
      <div className="theme_heading">
        <h1>FAQ</h1>
      </div>
      <div>
        <Accordion className="faq_wrapper" defaultActiveKey="">
          <Card>
            <Card.Header>
              <ContextAwareToggle eventKey="2">
                WHEN WILL FADED ARCADE LAUNCH?
              </ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="2">
              <Card.Body className="text-uppercase">
              Our VIP Pass Launched On AUGUST 5TH AT 8AM EST - THIS HAS NOW SOLD OUT! [<a className="link_t"href="https://opensea.io/collection/fadedarcade">Purchase On OpenSea</a>]. Follow Us On [<a className="link_t" href="https://twitter.com/fadedarcadenft">Twitter</a>] For Latest News And Updates On Our Upcoming Gamer Pass Launch This Fall.
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card>
            <Card.Header>
              <ContextAwareToggle eventKey="3">
                WHAT IS THE PRICE OF MINTING AN NFT?
              </ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="3">
              <Card.Body className="text-uppercase">
              VIP PASSES MINTED AT 0.1971 ETH.  THIS HAS NOW SOLD OUT! [<a className="link_t"href="https://opensea.io/collection/fadedarcade">Purchase On OpenSea</a>]. Follow Us On  [<a className="link_t" href="https://twitter.com/fadedarcadenft">Twitter</a>] For Latest News And Updates On Our Upcoming Gamer Pass Launch This Fall.
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header>
              <ContextAwareToggle eventKey="5">
                CAN I ASK MY OWN QUESTION?
              </ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="5">
              <Card.Body>SURE THING, JOIN US OVER ON TWITTER.</Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card>
            <Card.Header>
              <ContextAwareToggle eventKey="6">
                AM I THE CREATOR OF THE TIME PORTAL?
              </ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="6">
              <Card.Body>
                POSSIBLY! WE KNOW THE PORTAL CREATOR PLAYED ALL TEN GAMES IN THE
                FADED ARCADE BETWEEN 2022 AND 2025. WE BELIEVE THAT THESE GAMES
                FORMED THE INSPIRATION AND BECAME A CATALYST TO DEVELOP TIME
                TRAVEL. JOIN NOW, PLAY THE GAMES, UNLOCK THE MEGALOPOLIS
                MACHINE, EXTRACT THE CODE AND VERIFY YOUR BLOCKCHAIN RECORD
                USING YOUR NFT TO FIND OUT! [PLEASE RETURN TO 2071 AND SAVE THE
                FUTURE]
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>
      <div></div>
    </div>
  );
}

export default Faq;
