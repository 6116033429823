import "./index.css"

function Refrence() {
    return (
        <div className="refrence_container">
            <h1>KEY AND REFERENCE</h1>
            <p><span>* I.D.E.A.</span> = Interactive Digitally Experienced Art.</p>
            <p><span>** UTILITY NFT</span> = Think of your NFT as a lifetime ticket/pass to enter the Faded Arcade <i>(as well as a potentially lucrative investment)</i> <b>-- You will need to keep it in your crypto wallet so we can verify it's there and give you exclusive access to the games</b></p>
            <p><span>Def: Retrowave</span><br />= Retrowave is very much inspired by the '80s, but is mostly an idealized futuristic version of the '80s ... an attractive 'wish you where here' alternative timeline where everything's simply ACE!</p>
            <p><span>Def: Cyberpunk</span><br />= a subgenre of science fiction in a dystopian futuristic setting that tends to focus on a combination of lowlife/high tech and is firmly rooted in the New Wave science fiction movement of the 1960s and 1970s.</p>
        </div>
    )
}

export default Refrence;