import config from "./config.json";
import messages from "./messages.json";

import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";

config.messages = messages;

export { default as abi } from "./abi.json";
export { default as abi_splitter } from "./abi_splitter.json";
export { default as Helper } from "./helper";
export { default as Request } from "./request";
export { default as AppConfig } from "./context";

const _web3Modal = new Web3Modal({
  cacheProvider: true, // optional
  providerOptions: {
    walletconnect: {
      package: WalletConnectProvider, // required
      options: {
        infuraId: config.infuraId, // required
      },
    },
  },
});

export { _web3Modal, config };
