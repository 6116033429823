import Cover from "../components/cover";
import Team from "../components/team";
import Footer from "../components/footer";
import WhyJoin from "../components/why_join";
import Navbar from "../components/navbar";
import Refrence from "../components/refrence";
import Lost from "../components/lost_section";
import Not from "../components/not_section";
import Faq from "../components/faq";
import Whitepaper from "../components/whitepaper";
import Roadmaptest from "../components/road_map";
import SwiperModal from "../components/slider/modal";

import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function Home() {
  return (
    <div>
      <Navbar />
      <Cover />
      <SwiperModal />

      <Whitepaper />
      <Lost />
      <Not />

      <Refrence />

      {/* <WhyJoin /> */}
      <Roadmaptest />
      
      
      <Team />
      <Faq />
      <Footer />

      <ToastContainer theme="dark" />
    </div>
  );
}

export default Home;
