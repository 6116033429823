import "./index.css";
import { NFTRegistry } from "../nft";
import { config } from "../../config";

function GetWhitelist({ html }) {
  if (!config.raffle) {
    return null;
  }

  return <NFTRegistry />;
}

export default GetWhitelist;
