import "./style.css"
import img from "../../assets/images/new.png"
import {VideoPlayerModal} from "../whistia";
import GetWhitelist from "../get_whitelist";

function Lost() {
    return (
        <div className="lost_container">
            <div className="lost_image">
                <VideoPlayerModal id='h3fmyp1x9c' url={img}/>
            </div>
            <div className="lost_content">
                <h1>Lost In A World Of Your Own:</h1>
                <p>Race against time to master the mysterious Megalopolis Machine, unlock it's secret code and win valuable rewards (super rare NFTs), digital art and downloads/prizes.  Then battle your way up the leaderboards for fame and glory.</p>
                <p>Or simply take it easy and hang out in Sunnyvale. Get to know the locals (watch out for the freaky ones) and explore our pixelated portal back to the 80's where you'll find great music, films/footage, cool merch, bonus interviews and even random mini-games.</p>
                <GetWhitelist/>
            </div>
        </div>
    )
}

export default Lost;