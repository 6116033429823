import React from "react";
import { ethers } from "ethers";
import { MdDoubleArrow } from "react-icons/md";

import { Alert } from "..";
import {
  AppConfig,
  config,
  Helper,
  _web3Modal,
  abi_splitter as abi,
  Request,
} from "../../../../config";
import "./index.css";

class ClaimReward extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      alert: null,
      connected: false,
      submit: false,
    };
  }

  async handleConnect() {
    const provider = await _web3Modal.connect();

    this.provider = new ethers.providers.Web3Provider(provider);
    const network_id = await this.provider.send("net_version");

    this.setState({ submit: true });

    // NETWORK ID
    if (parseInt(network_id) !== parseInt(config.network)) {
      this.setState({
        alert: {
          type: "error",
          message: `Change network to ${Helper.networkName(config.network)}.`,
        },
        submit: false,
      });

      return false;
    }

    try {
      const signer = this.provider.getSigner();
      const address = await signer.getAddress();

      const contract = new ethers.Contract(
        config.splitter.contract_address,
        abi,
        signer
      );

      const paused = await contract.paused();

      // PAUSED
      if (paused) {
        this.setState({
          alert: {
            type: "error",
            message: config.messages.paused_contract,
          },
          submit: false,
        });

        return false;
      }

      const { data } = await Request.rewards({ address });

      if (data.status === "success") {
        this.setState({ minting: true });

        const { proof, token_count } = data.response;
        const transaction = await contract.getRoyalty(proof, token_count);

        transaction
          .wait()
          .then((response) => {
            console.log("Success", response);

            if (response.status === 1) {
              this.setState({
                minting: false,
                alert: {
                  type: "success",
                  message: config.messages.success_transaction,
                },
              });
            }
          })
          .catch((err) => {
            console.log("Err:", err);

            this.setState({
              alert: {
                type: "error",
                message: config.messages.failed_transaction,
              },
              minting: false,
            });

            return false;
          });
      } else {
        this.setState({
          alert: {
            type: "error",
            message: data.message,
          },
          submit: false,
          minting: false,
        });
      }
    } catch (err) {
      this.setState({
        alert: { type: "error", message: err.error.message },
        submit: false,
        minting: false,
      });
    }
  }

  render() {
    const { alert, minting } = this.state;

    return (
      <>
        {alert && <Alert {...alert} />}

        <div className="text-center">
          {minting && (
            <div className="proccess_message">Transaction in process...</div>
          )}

          <button
            disabled={this.state.submit}
            className="button text-uppercase"
            onClick={this.handleConnect.bind(this)}
          >
            <span>
              <MdDoubleArrow />
            </span>
            Claim rewards
          </button>
        </div>
      </>
    );
  }
}

ClaimReward.contextType = AppConfig;

export default ClaimReward;
