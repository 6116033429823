import "./index.css";
import img from "../../assets/images/f_logo.png";
import WhitelistModal from "../modal";

function Footer() {
  return (
    <div className="footer_container_bg">
      <div className="footer_container">
        <div className="footer_logo">
          <img src={img} alt="" />
        </div>
        <div className="footer_text">
          <span>
            <a
              href="https://twitter.com/thefadedarcade"
              rel="noreferrer"
              target="_blank"
            >
              TWITTER STORY
            </a>{" "}
            {/* *&nbsp; */}
          </span>
          <span>
            <a
              href="https://twitter.com/fadedarcadenft"
              rel="noreferrer"
              target="_blank"
            >
              TWITTER NFT
            </a>{" "}
            {/* *&nbsp; */}
          </span>
          <span>
            <a
              href="https://www.youtube.com/c/fadedarcade"
              rel="noreferrer"
              target="_blank"
            >
              YOUTUBE
            </a>{" "}
            {/* *&nbsp; */}
          </span>
          <span>
            <a
              href="https://fadedarcade.store/"
              rel="noreferrer"
              target="_blank"
            >
              STORE 
            </a>{" "}
            {/* *&nbsp; */}
          </span>
          <span>
            <a
              href="https://fadedarcade.com/credits"
              rel="noreferrer"
              target="_blank"
            >
              CREDITS 
            </a>{" "}
            {/* *&nbsp; */}
          </span>
          <span>
            <a
              href="https://securedownloads.s3.amazonaws.com/WPJ.pdf"
              rel="noreferrer"
              target="_blank"
            >
              WHITEPAPER JAPAN 
            </a>
          </span>
          
        </div>
        <div className="footer_button">
          {/* <button
            onClick={() =>
              window.open("https://sunnyvale.fadedarcade.com", "_blank")
            }
          >
            <span>
              <MdDoubleArrow />
            </span>
            ENTER ARCADE
          </button> */}

          <WhitelistModal html="Open Megabox" />
        </div>
      </div>
    </div>
  );
}

export default Footer;
