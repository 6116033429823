import "./index.css";
import GetWhitelist from "../get_whitelist";
function Whitepaper() {
  return (
    <div id="about" className="whitepaper_container">
      <div className="whitepaper_content">
        <h1>
          The Faded Arcade is an I.D.E.A.
          <span style={{ color: "#6cff00" }}>*</span>
        </h1>
        <p>
          A multidimensional, Retrowave-meets-Cyberpunk mash-up fantasy
          extravaganza. A pixel pushing, NFT collectible that serves up a heady
          cocktail of 80's arcade games cool, time jumping A.I. cyborgs from the
          future... And a double deceit that could end up getting this entire
          planet vaporized to f*%^k in 2071.
        </p>
        <p>"Wait! The Faded Arcade is a WHAT now?"</p>
        <p>
          With a limited number of UTILITY NFTs
          <span style={{ color: "#6cff00" }}>**</span> available each NFT holder
          becomes a player with a 24/7-365 entry pass to the most immersive,
          interactive gaming experience online today and many for years to come.
          [and we know - 'Cos &#x3e; Time Travel] ;-&#x7D;
        </p>
        <p>
          The Faded Arcade is the prequel to a story that's yet to be told and
          as a player (and who knows, possibly a time portal creator?) you'll be
          able to play all 10<span style={{ color: "#6cff00" }}>+</span>{" "}
          exclusive and lovingly crafted, new games (each influenced by 70's and
          80's classics but with plenty of functional and modern upgrades).
        </p>
        <p>Think of it 'resto-mod gaming' for the lucky few!</p>
      </div>
      <div className="whitepaper_widget">
        <p>Hit Play To Listen To The White Paper Below:</p>
        <iframe
          title="123"
          loading="lazy"
          src="https://quiet-licorice-27afb9.netlify.app/"
          style={{ display: "block", margin: "0 auto" }}
          frameBorder="0"
          height="300px"
          width="240px"
        ></iframe>
        <div className="btn_container">
          <p><a href="https://securedownloads.s3.amazonaws.com/WPJ.pdf" target="_blank" rel="noreferrer" style={{color: 'rgb(108, 255, 0)'}}>Download Japanese version</a></p>
          <GetWhitelist />
        </div>
      </div>
    </div>
  );
}

export default Whitepaper;
