import "./style.css"
import img1 from "../../assets/images/1.png"
import img2 from "../../assets/images/2.png"
import img3 from "../../assets/images/3.png"
import img4 from "../../assets/images/4.gif"


function Team() {
    return (
        <div className="team_container" id="team">
            <div className="team_heading">
                <h1>MEET THE FADED ARCADE TEAM...</h1>
            </div>
            <div className="team_images">
                <div className="sub_team_image">
                    <img src={img2} alt="" />
                    <h3>Ted Bayden</h3>
                    <p>THE CRYPTO-TRILLIONAIRE & PHILANTHROPIST DESPERATELY TRYING TO LOCATE THE MISSING QUANTUM ENGINEER AND CREATOR OF A HIGHLY UNSTABLE TIME PORTAL. [CHICAGO, 2071]</p>
                </div>
                <div className="sub_team_image">
                    <img src={img3} alt="" />
                    <h3>A.I. Syzygy</h3>
                    <p>A.I. CYBORG SPECIAL OPERATIVE SENT BACK TO PRESENT DAY TO ESTABLISH THE FADED ARCADE AND CREATE A BLOCKCHAIN RECORD TO IDENTIFY THE PORTAL CREATOR [USA, 2022]</p>
                </div>
                <div className="sub_team_image">
                    <img src={img1} alt="" />
                    <h3>Shaun Blonnell</h3>
                    <p>PRESENT DAY ARCADE HISTORIAN RECRUITED BY SYZYGY TO WORK ON HARMONIC RESONANCE THEORY, MODIFY GAMES AND ASSIST PLAYERS UNLOCK MEGALOPOLIS MACHINE [USA, 2022]</p>
                </div>
                <div className="sub_team_image">
                    <img src={img4} alt="" />
                    <h3>Portal Creator<span>[YOU?]</span></h3>
                    <p>KNOWN FADED ARCADE PLAYER [SUMMER 2022] AND CREATOR OF A HIGHLY UNSTABLE TIME PORTAL [CIRCA 2068] THAT'S THREATENING TO DESTROY THE FUTURE. [UNKNOWN, ????]</p>
                </div>
            </div>
        </div>
    )
}

export default Team;