// import "./index.scss";

const Alert = ({ message, type }) => {
  return (
    <div className="notification_box">
      <p dangerouslySetInnerHTML={{ __html: message }}></p>
    </div>
  );
};

export default Alert;
